<template>
  <div class="payment-dialog">
    <div class="px-10 text-center">
      <span class="mt-10 d-block font-20 black--text font-500">
        {{$t('completeYourPayment')}} <br> {{$t('totalPaid')}}: {{total}} $</span
      >
      <div class="text-center mt-4">
        <img
          width="50px"
          :src="require('@/assets/images/icons/paypal.png')"
          alt=""
        />
      </div>
      <v-btn
        class="c-btn my-4 mx-1"
        color="grey"
        min-width="140"
        height="40"
        @click="$emit('close')"
        ><span class="white--text font-300 font-20"
          >{{$t('close')}}</span
        ></v-btn
      >
      <v-btn
        class="c-btn my-4 mx-1"
        color="primary"
        min-width="140"
        height="40"
        @click="$emit('pay')"
        ><span class="white--text font-300 font-20"
          >{{$t('ok')}}</span
        ></v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    total: {
      type: String,
      default: '0',
    },
  },
};
</script>

<style lang="scss"></style>
