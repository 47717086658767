<template>
  <div>
    <!-- items -->
    <v-row v-if="!loading && item.bids.length > 0">
      <v-col cols="12" md="4" v-for="(bid, i) in item.bids" :key="i">
        <product-card
          :slotBtn="true"
          :img="require('@/assets/images/home/laptop.png')"
          :item="bid"
        >
          <template name="slotBtn">
            <div class="d-flex align-center justify-center mb-2">
              <v-btn
                class="c-btn px-1 px-10"
                color="primary"
                elevation="0"
                height="35"
                @click="acceptHandler(bid)"
                ><span class="white--text font-20 font-300 px-5">{{
                  $t("accept")
                }}</span></v-btn
              >
            </div>
          </template>
        </product-card>
      </v-col>
    </v-row>

    <!-- if empty -->
    <div class="text-center mt-8">
      <no-items
        v-if="!loading && item.bids.length == 0"
        :title="$t('noBidsYet')"
      ></no-items>
    </div>

    <!-- if loading -->
    <div v-if="loading">
      <v-row>
        <v-col cols="12" md="4" v-for="n in 3" :key="n">
          <skeleton-card />
        </v-col>
      </v-row>
    </div>
    <!-- payment dialog -->
    <v-dialog v-model="dialog" max-width="400" content-class="white">
      <payment-details
        :total="item.budget"
        @pay="paymentHandler"
        @close="dialog = false"
      ></payment-details>
    </v-dialog>
  </div>
</template>

<script>
import ProductCard from "../../../../../components/core/ProductCard.vue";
import PaymentDetails from "../../../../../components/dialogs/PaymentDetails.vue";
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: { ProductCard, PaymentDetails },
  data: () => ({
    products: [],
    dialog: false,
  }),
  methods: {
    async acceptHandler(bid) {
      let formData = new FormData();
      formData.append("package_id", this.item.id);
      formData.append("trip_id", bid.id);
      formData.append("order_id", this.item.order_id);
      await this.$axios.post(`acceptTripAsSender`, formData).then((res) => {
        if (res.data.success) {
          this.$store.dispatch("showSnack", {
            text: res.data.message,
            color: "success",
          });
          this.$emit("itemUpdated");

          this.dialog = true;
        } else {
          this.$store.dispatch("showSnack", {
            text: res.data.message,
            color: "error",
          });
        }
      });
    },
    async paymentHandler() {
      let formData = new FormData();
      formData.append("order_id", this.item.order_id);
      let { data } = await this.$axios.post(`payWithpaypal`, formData);
      if (data.success) {
        window.location.href = data.data.paypal_link;
      }
    },
  },

};
</script>

<style></style>
